var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cyrb53 = exports.javaHashCode = exports.murmurhash3_32_gc = void 0; // @ts-nocheck 
// https://github.com/artem0/canvas-fingerprinting/blob/master/hash/murmurhash3.js
// output - 3705295134 a hashed number of the string.
// https://en.wikipedia.org/wiki/MurmurHash

function murmurhash3_32_gc(key, seed) {
  var remainder, bytes, h1, h1b, c1, c2, k1, i;
  remainder = key.length & 3; // key.length % 4

  bytes = key.length - remainder;
  h1 = seed;
  c1 = 3432918353;
  c2 = 461845907;
  i = 0;

  while (i < bytes) {
    k1 = key.charCodeAt(i) & 255 | (key.charCodeAt(++i) & 255) << 8 | (key.charCodeAt(++i) & 255) << 16 | (key.charCodeAt(++i) & 255) << 24;
    ++i;
    k1 = (k1 & 65535) * c1 + (((k1 >>> 16) * c1 & 65535) << 16) & 4294967295;
    k1 = k1 << 15 | k1 >>> 17;
    k1 = (k1 & 65535) * c2 + (((k1 >>> 16) * c2 & 65535) << 16) & 4294967295;
    h1 ^= k1;
    h1 = h1 << 13 | h1 >>> 19;
    h1b = (h1 & 65535) * 5 + (((h1 >>> 16) * 5 & 65535) << 16) & 4294967295;
    h1 = (h1b & 65535) + 27492 + (((h1b >>> 16) + 58964 & 65535) << 16);
  }

  k1 = 0;

  switch (remainder) {
    case 3:
      k1 ^= (key.charCodeAt(i + 2) & 255) << 16;
      break;

    case 2:
      k1 ^= (key.charCodeAt(i + 1) & 255) << 8;
      break;

    case 1:
      k1 ^= key.charCodeAt(i) & 255;
      k1 = (k1 & 65535) * c1 + (((k1 >>> 16) * c1 & 65535) << 16) & 4294967295;
      k1 = k1 << 15 | k1 >>> 17;
      k1 = (k1 & 65535) * c2 + (((k1 >>> 16) * c2 & 65535) << 16) & 4294967295;
      h1 ^= k1;
      break;

    default:
      return 0;
  }

  h1 ^= key.length;
  h1 ^= h1 >>> 16;
  h1 = (h1 & 65535) * 2246822507 + (((h1 >>> 16) * 2246822507 & 65535) << 16) & 4294967295;
  h1 ^= h1 >>> 13;
  h1 = (h1 & 65535) * 3266489909 + (((h1 >>> 16) * 3266489909 & 65535) << 16) & 4294967295;
  h1 ^= h1 >>> 16;
  return h1 >>> 0;
}

exports.murmurhash3_32_gc = murmurhash3_32_gc; // taken from same above repo

const javaHashCode = (string, K) => {
  var hash = 0;

  if (string.length === 0) {
    return hash;
  }

  let char;

  for (var i = 0; i < string.length; i++) {
    char = string.charCodeAt(i);
    hash = K * ((hash << 5) - hash) + char;
    hash = hash & hash;
  }

  return hash;
};

exports.javaHashCode = javaHashCode; // reference - https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript#answer-52171480
// output - 6533356943844037

const cyrb53 = function (str, seed = 0) {
  let h1 = 3735928559 ^ seed,
      h2 = 1103547991 ^ seed;

  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }

  h1 = Math.imul(h1 ^ h1 >>> 16, 2246822507) ^ Math.imul(h2 ^ h2 >>> 13, 3266489909);
  h2 = Math.imul(h2 ^ h2 >>> 16, 2246822507) ^ Math.imul(h1 ^ h1 >>> 13, 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

exports.cyrb53 = cyrb53;
export default exports;